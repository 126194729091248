<template>
  <p-container>
    <template slot="toolbar">
        <a-button type="primary">关闭评审</a-button>
        <a-button type="primary">汇总结果导出</a-button>
    </template>

    <template slot="searchbar">
        <a-form layout="inline">
            <a-form-item label="专家组">
                <a-select placeholder="专家组" style="width:120px;">
                    <a-select-option value="fm">---</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="维持建议">
                <a-select placeholder="维持建议" style="width:120px;">
                    <a-select-option value="fm">---</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="评审状态">
                <a-select placeholder="评审状态" style="width:120px;">
                    <a-select-option value="fm">---</a-select-option>
                </a-select>
            </a-form-item>     
            <a-form-item>
                <a-select style="width:120px;">
                    <a-select-option value="fm">---</a-select-option>
                </a-select>
            </a-form-item>  
            <a-form-item>
                <a-input style="width:120px;"/>
            </a-form-item>     
            
            <a-form-item>
                <a-button type="primary" html-type="submit">
                    <a-icon type="search" />检索
                </a-button>
            </a-form-item>
        </a-form>
    </template>
    
    <a-table bordered :data-source="dataSource" :columns="columns" size="middle" :row-selection="rowSelection"></a-table>

  </p-container>
</template>

<script>
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const treeData = [
  {
    title: '全部',
    key: '0-0',
    children: [
      {
        title: 'parent 1-0',
        key: '0-0-0',
        children: [
          { title: 'leaf', key: '0-0-0-0' },
          { title: 'leaf', key: '0-0-0-1' },
        ],
      },
      {
        title: 'parent 1-1',
        key: '0-0-1',
        children: [
            { title: 'title0010', key: '0-0-1-0' }
        ],
      },
    ],
  },
];
export default {
  data() {
    return {
        treeData,
        rowSelection,
        dataSource: [
            {
            key: '0',
            name: '养生型合成石及其制品',
            age: '201210499081.5',
            address: '中国电力科学研究院',
            }
        ],
        columns: [
            {
            title: '专利名称',
            dataIndex: 'name',
            width: '30%'
            },
            {
            title: '申请号',
            dataIndex: 'age',
            },
            {
            title: '申请人',
            dataIndex: 'address',
            }
        ],
    }
  },
  methods: {
      handleEdit(){

      }
  }
};
</script>

<style>

</style>